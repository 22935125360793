$(document).ready(function () {


    $('body').on('click', '.js-show-more-posts', function (e) {

        e.preventDefault();

        post_count = $('.js-blog-container .js-blog-listing').length;

        post_data = {count: post_count};

        updateBlogPosts(post_data);

    });


});

function updateBlogPosts(post_data) {

    url = '../php/ajax/blog.php?action=fetch';

    $.post(url, post_data, function (data) {

        try {
            response = JSON.parse(data);

            if (response.success) {

                $('.js-blog-container').append(response.html);

                if (response.all_posts <= $('.js-blog-container .js-blog-listing').length) {
                    $('.js-show-more-posts').hide();
                }

            }
            else {
                alert(response.error_message);
            }

        }
        catch (e) {
            alert(e + '<br />' + data);
        }

    });
}