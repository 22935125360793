$(window).on('ready load change resize orientationchange', function () {

    squareUp();

});

function squareUp() {

    $('.js-square').each(function () {
        w = $(this).width();
        $(this).height(w);
    });
}