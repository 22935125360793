$(document).ready(function () {


    var collapsingHeader = $(".js-header-container");
    var navbarHeight = collapsingHeader.outerHeight();

// Don't know what device they'll be on so set mt here
    $("body").css("margin-top", navbarHeight);


    var upArrow = $(".js-up-arrow");

// If user at bottom of page then show scroll up arrow.
    setInterval(function () {
        if ($(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
            upArrow.fadeTo(200, .85);
        } else {
            upArrow.fadeTo(200, 0);
        }
    }, 250);

// Add click event to up arrow
    upArrow.click(function () {
        $("html, body").animate({scrollTop: 0}, "slow");
        return false;
    });


// Hide Header on on scroll down
    var contactHeader = $(".js-contact-header");
    var mainHeader = $(".js-main-header");
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;

    $(window).scroll(function (event) {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 50);


    function hasScrolled() {
        var st = $(this).scrollTop();

        // Return immediately if they haven't scrolled past the delta
        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop) {
            contactHeader.slideUp();
            mainHeader.addClass("nav-down");
        } else {
            // Scroll Up
            if (st < (navbarHeight / 3)) {
                contactHeader.slideDown();
                mainHeader.removeClass("nav-down");
            }
        }

        lastScrollTop = st;
    }


    var searchToggle = $(".js-search");
    var searchForm = $(".js-search-form");


    searchToggle.click(function () {
        searchForm.slideToggle();
    });


    var menuToggle = $(".js-nav-toggle");
    var menu = $(".js-nav");


    menuToggle.click(function () {
        $(this).toggleClass('open');
        menu.slideToggle();
    })

    $('.js-supplier-slideshow').initialiseCycle({
        'fx': 'carousel',
        'slides': '> .js-supplier-slide',
        'carousel-fluid': true,
        'carousel-visible': 4,
        'timeout': 2000,
    });

});

$.fn.initialiseCycle = function (input_options) {

    slideshow = this;

    options = {
        'slides': '> li',
        'swipe': true,
        'speed': 1000,
        'easing': 'easeOutCirc',
        'manualSpeed': 1000,
        'timeout': 4000,
        'next': '.next',
        'prev': '.prev',
        'log': false
    };

    $.extend(options, input_options);

    slideshow.cycle(options);

}
