$(document).ready(function () {

    //icon_url = window.location.protocol + "//" + window.location.hostname + "/assets/images/map-marker.png";

    if ($('.js-map').length > 0) {
        $('.js-map')
            .gmap3({
                center: [53.3454696, -1.2987792],
                zoom: 15,
                navigationControl: false,
                scrollwheel: false,
                streetViewControl: false
            }).marker([
            {address: "Unit 7, Vector 31 Network Centre, Waleswood Way, Wales, Sheffield S26 5NU, UK "}
        ]);
    }

});
