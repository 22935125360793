$(document).ready(function () {

    //Verify emails
    function isValidEmailAddress(emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    $('body').on('click', '.js-to-checkout', function () {
        location.href = 'checkout';
    });

    $('body').on('change', '.js-basket-quantity', function () {

        quantity = $(this).val();
        product_id = $(this).closest('.basket__product').attr('data-product_id');
        variant_id = $(this).closest('.basket__product').attr('data-variant_id');

        post_data = {
            quantity: quantity,
            product_id: product_id,
            variant_id: variant_id
        };

        updateBasket(post_data);

    });

    $('body').on('click', '.js-add-to-basket', function (e) {

        e.preventDefault();

        button = $(this);

        //validation
        msg = "";
        valid = true;
        error_msg = "";

        if (!button.hasClass('working')) {

            button.addClass('working');

            quantities = $('.js-add-quantity').serialize();

            product_id = $(this).attr('data-product_id');

            post_data = 'product_id=' + product_id;
            post_data += '&' + quantities;

            addToBasket(post_data);
        }

    });

    function updateBasket(post_data) {

        url = "../php/ajax/shop.php?action=update-basket";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    $('.js-basket').html(response.basket_html);
                    button.removeClass('working');

                    squareUp();
                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = error_msg;
                    Apprise(msg);
                    button.removeClass('working');
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = error_msg;
                Apprise(msg);
                button.removeClass('working');
            }

        });
    }

    function addToBasket(post_data) {

        url = "../php/ajax/shop.php?action=add-to-basket";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    $('.js-basket').html(response.basket_html);
                    button.removeClass('working');

                    squareUp();
                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = error_msg;
                    Apprise(msg);
                    button.removeClass('working');
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = error_msg;
                Apprise(msg);
                button.removeClass('working');
            }

        });
    }

    $('.js-payment-method').on('change ready load', function () {

        $('.js-payment-by').slideUp();
        $('.js-payment-by-' + $(this).val()).slideDown();

    });

    $('.js-billing_address_same').on('change', function () {

        if ($(this).is(':checked')) {
            $('.js-billing-address').hide();
        }
        else {
            $('.js-billing-address').show();
        }

    });

    $('.js-process-checkout').on('click', function () {

        button = $(this);
        valid = true;
        msg = '';

        if (!button.hasClass('working')) {

            button.addClass('working');

            msg = "";
            valid = true;

            $('.required:visible').each(function () {
                if (!$(this).val()) {
                    valid = false;

                    placeholder = $(this).attr('placeholder');
                    msg += "<p>" + placeholder + " is empty</p>";
                    $(this).addClass('attention');
                }
            });

            if ($('.js-payment-method').val() == 'card') {

                //Card validation
                $('.required.ghost').each(function () {
                    if (!$(this).val()) {
                        valid = false;

                        placeholder = $(this).attr('placeholder');
                        msg += "<p>" + placeholder + " is empty</p>";
                        $(this).addClass('attention');
                    }
                });

                $('.js-card-field').each(function () {

                    if ($(this).val() == 'incomplete') {
                        valid = false;

                        placeholder = $(this).attr('placeholder');
                        msg += "<p>" + placeholder + " is only partially complete</p>";
                    }

                    if ($(this).val() == 'error') {
                        valid = false;

                        error = $(this).attr('data-error');
                        msg += "<p>" + error + "</p>";

                        $(this).markCardFieldForAttention();
                    }

                });
            }

            if (valid) {
                //Check terms agreed
                if (!$('input[name=agree_terms]').is(':checked')) {
                    valid = false;
                    msg += "<p>You must agree to the terms and conditions before placing your order.</p>"
                }
            }

            if (!isValidEmailAddress($('.js-email').val())) {
                valid = false;
                msg += "<p>Please ensure that the lead passenger's email is formatted correctly.</p>";
            }

            if (!valid) {
                Apprise("<h2>Sorry, there appears to be a few issues with your entries...1</h2>" + msg);
                button.removeClass('working');
                if ($('.attention').length > 0) {
                    $('html, body').animate({
                        scrollTop: $('.attention').first().offset().top - $('header').height() - 40
                    }, 200);
                }
            }

            if (valid) {

                if ($('.js-payment-method').val() == 'card') {
                    //Prep additional token details
                    if ($('.js-billing_address_same').is(':checked')) {
                        //Use lead traveller's address
                        name_on_card = $('input[name=name_on_card]').val();
                        address_line1 = $("input[name='customer[address_line_1]']").val();
                        address_zip = $("input[name='customer[postcode]']").val();
                        address_country = 'GB';

                        tokenData = {
                            name: name_on_card,
                            address_line1: address_line1,
                            address_zip: address_zip,
                            address_country: address_country
                        };

                        tokenizePayment(tokenData, msg, valid);
                    }
                    else {
                        //Use details from form
                        name_on_card = $('input[name=name_on_card]').val();
                        address_line1 = $("input[name='billing_address[address_line_1]']").val();
                        address_zip = $("input[name='billing_address[postcode]']").val();
                        address_country = 'GB';

                        tokenData = {
                            name: name_on_card,
                            address_line1: address_line1,
                            address_zip: address_zip,
                            address_country: address_country
                        };

                        tokenizePayment(tokenData, msg, valid);
                    }
                }
                else {
                    finaliseOrder($('.js-payment-method').val());
                }

            }
        }
    })

});

function tokenizePayment(tokenData, msg, valid) {

    if (tokenData.address_country == null) {
        delete tokenData.address_country;
    }

    if (tokenData.address_line1 == null) {
        delete tokenData.address_line1;
    }

    if (tokenData.address_zip == null) {
        delete tokenData.address_zip;
    }

    if (tokenData.name == null) {
        delete tokenData.name;
    }

    //Tokenise payment
    stripe.createToken(cardNumber, tokenData).then(function (result) {

        if (result.error) {
            valid = false;

            $('body').attr('data-valid', false);

            stripe_msg = '<h2>There was a problem with your payment</h2>' +
                '<p>Unfortunately we were unable to take payment - the reason for this problem is "' + result.error.message + '"</p>' +
                '<p>Please try again, you may wish to contact your bank to find out the reason for this, or you may attempt payment with another card.</p>' +
                '<p> If the problem should persist please contact the Contours support team and they will do their utmost to help.</p>' +
                '<p> Please note that payment will not be duplicated - it will only ever be taken once.</p>';

            msg += stripe_msg;

            $('body').attr('data-valid', valid);
            $('body').attr('data-msg', msg);

            Apprise("<h2>Sorry, there appears to be a few issues with your entries...2</h2>" + $('body').attr('data-msg'));
            $('button.js-process-checkout').removeClass('working');
        } else {
            $('body').attr('data-valid', valid);
            $('body').attr('data-msg', msg);
            finaliseOrder(result.token.id);
        }
    });
}


function finaliseOrder(token) {

    msg = $('body').attr('data-msg');
    //valid = $('body').attr('data-valid');
    valid = true;

    if (valid) {
        //Save and create booking
        total_cost = $('.js-order-summary').attr('data-total-cost');
        total_deposit = $('.js-order-summary').attr('data-total-deposit');
        balance_due = $('.js-order-summary').attr('data-balance-due');
        full_payment_due = $('.js-order-summary').attr('data-full-payment-due');

        post_data = $('.checkout').find('input, select, textarea').serialize();
        post_data += '&token=' + token;

        url = "../../php/ajax/shop.php?action=create-order";

        $.post(url, post_data, function (data) {

                try {
                    response = JSON.parse(data);

                    if (response.success) {
                        Apprise('Order placed');
                    }
                    else {
                        msg = response.error_list;
                        Apprise(msg);
                        $('button.js-process-checkout').removeClass('working');
                    }
                }
                catch
                    (e) {
                    msg = '<h2>Sorry, there was a critical error during the purchase process</h2><p>Our sincere apologies for the inconvenience. Please contact us with details of the error as shown below so we can remedy the problem.</p>';
                    msg += e + '<br /><br /> DATA:' + data;
                    Apprise(msg);
                    $('button.js-process-checkout').removeClass('working');
                }

            }
        );
    }
    else {
        Apprise("<h2>Sorry, there appears to be a few issues with your entries...3</h2>" + msg);
        $('button.js-process-checkout').removeClass('working');
        if ($('.attention').length > 0) {
            $('html, body').animate({
                scrollTop: $('.attention').first().offset().top - $('header').height() - 40
            }, 200);
        }
    }
}