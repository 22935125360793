$(document).ready(function () {

    $(".nav").sortable(
        {
            items: 'li',
            placeholder: 'row-placeholder', //placeholder class
            handle: '.drag',

            update: function (event, ui) {
                new_order = new Array();

                $(this).find('li').each(function (index) {
                    new_order[index] = $(this).attr('data-id');
                });

                new_order = new_order.toString();

                post_data = {
                    order: new_order
                };


                url = '../php/ajax/nav.php?action=save-order';


                $.post(url, post_data, function (data) {

                    try {
                        notify("Saving new order...");

                        response = JSON.parse(data);

                        if (response.success) {

                            denotify();

                        }
                        else {
                            Apprise(response.error_message);

                        }

                    }
                    catch (e) {
                        Apprise(e + '<br />' + data);
                    }

                });

            }

        });

    $('ul.nav .delete').on('click', function () {

        row = $(this).closest('li');

        id = row.attr('data-id');

        var options = {
            buttons: {
                confirm: {
                    text: 'Yes, please delete it',
                    action: function (e) {
                        Apprise('close');
                        notify("Deleting menu item...")
                        deleteNav(id);
                    }
                },
                cancel: {
                    text: 'No, don\'t delete it',
                    action: function (e) {
                        Apprise('close');
                    }
                }
            },

            override: false
        };

        Apprise("<h2>Really?</h2>" +
            "<p>Do you definitely want to delete this menu item indefinitely?</p>", options);

    });

    function deleteNav(id) {
        url = "../php/ajax/nav.php?action=delete";

        data = {id: id};

        $.post(url, data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {
                    location.href = "nav";
                }

                return response.success;
            }
            catch (e) {
                Apprise(e + '<br />' + data);
            }

        });
    }

    $('ul.nav .edit').on('click', function () {

        row = $(this).closest('li');

        id = row.attr('data-id');

        location.href = "nav/edit/" + id;

    });

    //
    $('form.nav button.add').on('click', function () {

        //validation
        msg = "";
        valid = true;
        error_msg = "";

        //
        form = $(this).closest('form');

        //check required fields
        form.checkRequiredFields();

        if (!valid) {
            error_msg += msg;
        }

        //
        if (valid) {
            post_data = form.serialize();

            url = "../php/ajax/nav.php?action=add";

            $.post(url, post_data, function (data) {

                try {
                    notify('Setting up your menu item...');

                    response = JSON.parse(data);

                    if (response.success) {
                        location.href = "nav#" + response.id;
                    }
                    else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        Apprise(msg);
                        denotify();
                    }
                }
                catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    Apprise(msg);
                    denotify();
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            Apprise(msg);
            denotify();
        }

    });

    $('form.nav button.edit').on('click', function () {

        //validation
        msg = "";
        valid = true;
        error_msg = "";

        //
        form = $(this).closest('form');

        //check required fields
        form.checkRequiredFields();

        if (!valid) {
            error_msg += msg;
        }

        //
        if (valid) {
            post_data = form.serialize();

            url = "../php/ajax/nav.php?action=update";

            $.post(url, post_data, function (data) {

                try {
                    notify('Saving your changes...');

                    response = JSON.parse(data);

                    if (response.success) {
                        location.href = "nav#" + response.id;
                    }
                    else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        Apprise(msg);
                        denotify();
                    }
                }
                catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    Apprise(msg);
                    denotify();
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            Apprise(msg);
            denotify();
        }

    });


});
